/* Servicios.css */

.servicios {
    text-align: center;
    padding: 40px;
    padding-top: 10px;
}

.servicios h1 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 46px;
    color: #547cb8;
}

.servicios-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Crea tres columnas de igual ancho */
  gap: 5px; /* Espacio entre los elementos */
  padding: 20px;
  justify-content: center;
}




.servicio {
    background: #f9f9f9; /* Fondo claro para cada servicio */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Sombra suave */
    padding: 20px;
    transition: transform 0.3s ease; /* Animación al pasar el mouse */
}

.servicio:hover {
    transform: translateY(-5px); /* Efecto al pasar el mouse */
}

.servicio img {
    width: 350px; /* Ancho fijo */
    height: 200px; /* Altura fija, ten cuidado con la distorsión */
    object-fit: cover; /* Asegura que la imagen cubra el espacio sin distorsionarse */
    border-radius: 8px; /* Opcional: Añade bordes redondeados a la imagen */
}


.servicio h2 {
    margin: 20px 0 10px; /* Espacio alrededor del título */
    color: #547cb8
}

.servicio p {
  margin-bottom: 15px; /* Ajusta este valor según sea necesario */
  text-align: left;
  color: black;
  line-height: 1.3;
  font-size: 18px; /* Tamaño de fuente más pequeño */
}


/* Estilos previos... */

.servicio button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #547cb8;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.servicio button:hover {
    background-color: #365f8c;
}
.modal h2 {
    margin: 20px 0 10px; /* Espacio alrededor del título */
    color: #547cb8
}
.modal p {
    text-align: justify; /* Ajuste existente, ejemplo */
    color: black; /* Negro */
}
.modal {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos hijos horizontalmente */
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: auto;
    max-width: 600px; /* Ajusta según la necesidad */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Permite desplazamiento si el contenido excede el alto del modal */
    max-height: 80%; /* Limita el alto máximo para asegurar margen alrededor del modal */
    margin-top: 5vh; /* Asegura un espacio desde el top de la ventana */
  }
  
  .modal-content h2, .modal-content h3 {
    margin-top: 20px; /* Asegura que el título tenga un margen superior */
    text-align: center;
    width: 100%;
  }
  
  .modal-content img {
    max-width: 95%; /* Ajusta el ancho máximo para evitar que se corte */
    max-height: 200px; /* Limita la altura de la imagen */
    object-fit: contain; /* Asegura que la imagen se ajuste sin distorsionarse */
    margin-top: 20px; /* Espacio arriba de la imagen */
  }
  
  .modal-content p, .modal-content ul {
    text-align: left; /* Mantiene el texto y las listas alineadas a la izquierda */
    max-width: 90%; /* Limita el ancho para mejorar la legibilidad */
  }
  
  .modal-content ul {
    padding-left: 20px; /* Añade indentación a la lista */
    list-style-position: inside; /* Alinea los marcadores de la lista con el texto */
  }
  
  .modal-content li {
    margin-bottom: 10px; /* Espacio entre puntos de la lista */
  }
  
  
/* Estilos existentes para PC se mantienen sin cambios */

@media screen and (max-width: 768px) {
  /* Ajusta el contenedor de servicios para mejorar el padding en dispositivos móviles */
  .servicios {
    padding: 20px;
    padding-top: 10px;
  }

  /* Reduce el tamaño del título para dispositivos móviles */
  .servicios h1 {
    font-size: 22px; /* Tamaño reducido para dispositivos móviles */
    margin-left: 0;
  }

  /* Ajusta la cuadrícula para mostrar solo una columna en dispositivos móviles */
  .servicios-grid {
    grid-template-columns: 1fr; /* Cambio a una sola columna */
    padding: 10px; /* Reducción del padding */
  }

  /* Ajusta el tamaño de las imágenes de servicio para que sean más pequeñas y manejables en móviles */
  .servicio img {
    width: 100%; /* Hace que las imágenes sean responsivas y ocupen el 100% del ancho del contenedor */
    height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
  }

  /* Modifica el estilo de cada servicio para una mejor visualización en móviles */
  .servicio {
    padding: 10px; /* Reduce el padding para maximizar el espacio */
  }

  /* Ajusta el contenedor del modal para dispositivos móviles */
  .modal-content {
    max-width: 90%; /* Aumenta el ancho máximo para una mejor visualización */
    margin-top: 10vh; /* Ajusta el margen superior para centrar mejor el modal */
    overflow-y: auto; /* Asegura que el contenido pueda desplazarse si es demasiado largo */
    padding: 10px; /* Reduce el padding interior para maximizar el espacio */
  }
}

