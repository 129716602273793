body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  /* Configuración predeterminada para el tema claro */
  --link-color: #547cb8; 
  --background-color: #FFFFFF; /* Fondo blanco */
  --text-color: #333; /* Texto oscuro */
  --accent-color: #E0F7FA; /* Azul clarito para acentos */
}

[data-theme="dark"] {
  /* Configuración para el tema "oscuro" que en realidad es azul clarito */
  --background-color: #E0F7FA; /* Fondo azul clarito */
  --text-color: #333; /* Mantener el texto oscuro */
  --accent-color: #FFFFFF; /* Acentos en blanco, si necesario */
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  color: var(--link-color);
}