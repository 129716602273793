.proyectos-container {
  text-align: center;
  padding: 40px;
}

.proyectos-container h1 {
  margin-top: 0;
  margin-bottom: 80px;
  font-size: 46px;
  color: #547cb8;
  text-transform: uppercase;
}

.contenido-container {
  display: grid;
  grid-template-columns: 4fr 4fr;
  gap: 0px;
  align-items: start;
}

.proyectos-galeria-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 15px;
  justify-content: flex-end;
}

.proyectos-galeria-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px; 
}

.proyectos-video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Agregar un espacio inferior entre los videos */
}

.proyectos-video-container video {
  max-width: 100%; /* Ajustar el ancho máximo del video al contenedor */
  height: auto; /* Ajustar la altura automáticamente para mantener la proporción */
  border-radius: 20px; /* Mantener el radio de borde para los videos */
}

.proyectos-video-container video.video-1 {
  max-width: 100%; /* Establecer un ancho máximo del video 1 al 100% */
  height: auto; /* Ajustar la altura automáticamente para mantener la proporción */
}

.proyectos-video-container video.video-2 {
  max-width: 100%; /* Establecer un ancho máximo del video 2 al 100% */
  height: auto; /* Ajustar la altura automáticamente para mantener la proporción */
}

@media screen and (max-width: 768px) {
  .proyectos-galeria-container {
      grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .proyectos-container {
    padding: 20px; /* Reducir el relleno para dispositivos móviles */
  }

  .proyectos-container h1 {
    font-size: 22px; /* Tamaño reducido para dispositivos móviles */
    margin-left: 0;
  }

  .contenido-container {
    grid-template-columns: 1fr; /* Cambiar a una sola columna en dispositivos móviles */
    gap: 10px; /* Reducir el espacio entre los elementos */
  }

  .proyectos-galeria-container {
    grid-template-columns: 1fr; /* Cambiar a una sola columna en dispositivos móviles */
  }

  .proyectos-video-container video {
    width: 100%; /* Ajustar el ancho del video al contenedor en dispositivos móviles */
    max-width: none; /* Eliminar el máximo ancho del video */
    height: auto; /* Ajustar la altura automáticamente */
  }

  .proyectos-video-container video.video-1 {
    height: auto; /* Ajustar la altura automáticamente */
    max-height: 350px; /* Limitar la altura máxima del video */
    border-radius: 10px; /* Reducir el radio de borde del video */
    object-position: center; /* Centrar el video en dispositivos móviles */
  }

  .proyectos-video-container video.video-2 {
    max-height: 600px; /* Limitar la altura máxima del video */
    border-radius: 10px; /* Reducir el radio de borde del video */
  }
}
