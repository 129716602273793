.contacto-container {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.contacto-container h1 {
    color: #547cb8; /* Cambia esto según tu paleta de colores */
    margin-bottom: 40px;
    font-size: 46px;
}

.contacto-container p {
    color: #666; /* Color suave para el texto */
    margin-bottom: 20px;
}

.info-contacto p {
    color: #333; /* Color más oscuro para mayor contraste */
    margin: 10px 0;
}

/* Estilos generales para el formulario */
.formulario-contacto {
    margin-top: 20px;
    background-color: #f9f9f9; /* Fondo claro para el formulario */
    padding: 20px;
    border-radius: 8px; /* Bordes redondeados para el formulario */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil para dar profundidad */
    max-width: 500px; /* Ajusta este valor según prefieras */
    margin-left: auto;
    margin-right: auto;
}

/* Mejoras en los inputs y textarea */
.formulario-contacto input,
.formulario-contacto textarea {
    width: calc(100% - 20px); /* Ajusta el ancho considerando el padding */
    padding: 15px; /* Más espacio interior para una mayor legibilidad */
    margin-bottom: 20px;
    border: 2px solid #ddd; /* Borde más definido */
    border-radius: 8px; /* Bordes más redondeados */
    box-sizing: border-box;
    transition: border-color 0.3s; /* Transición suave al cambiar el borde */
}

.formulario-contacto input:focus,
.formulario-contacto textarea:focus {
    border-color: #547cb8; /* Color del borde al enfocar */
    outline: none; /* Elimina el outline predeterminado para enfocar en el cambio de color del borde */
}

/* Estilo del botón de enviar más llamativo */
.formulario-contacto button {
    background-color: #547cb8; /* Color principal del botón */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s; /* Transición suave para color y transformación al hacer hover */
    font-weight: bold; /* Texto en negrita para el botón */
}

.formulario-contacto button:hover {
    background-color: #365f91; /* Color al hacer hover */
    transform: scale(1.05); /* Efecto de crecimiento sutil al pasar el mouse */
}

/* Ajuste para responsividad */
@media (max-width: 768px) {
    .formulario-contacto {
        width: calc(100% - 40px); /* Ajusta el formulario para pantallas más pequeñas */
        margin: 20px;
        padding: 15px;
    }
}


/* Estilos para el mapa */
/* Ajustes para el mapa de lado a lado */
.mapa iframe {
    width: 100vw; /* Establece el ancho del iframe al ancho de la ventana del navegador */
    height: 400px; /* Mantiene la altura fija para el mapa */
    border: none;
    margin-top: 20px;
    margin-left: calc(-50vw + 50%); /* Centra el mapa */
    margin-right: calc(-50vw + 50%);
    border-radius: 0; /* Opcional: elimina los bordes redondeados */
}

@media (max-width: 768px) {
    .contacto-container {
        padding: 10px;
    }

    .formulario-contacto input,
    .formulario-contacto textarea,
    .mapa iframe {
        width: 100%; /* Garantiza que todos los elementos sean responsivos */
        margin-left: 0; /* Ajuste para dispositivos móviles */
        margin-right: 0;
    }
}
.success-message {
    background-color: #dff0d8; /* Verde claro para éxito */
    color: #3c763d; /* Texto en verde oscuro */
    padding: 20px; /* Aumenta el padding para más espacio interior */
    margin: 20px 0;
    border-radius: 8px; /* Bordes ligeramente más redondeados para un look suave */
    border: 1px solid #d6e9c6;
    text-align: center;
    font-size: 18px; /* Tamaño de texto más grande para mayor visibilidad */
    font-weight: bold; /* Hacer el texto en negrita para que destaque más */
}
.formulario-contacto select {
    width: calc(100% - 20px); /* Ajusta el ancho considerando el padding */
    padding: 15px; /* Más espacio interior para una mayor legibilidad */
    margin-bottom: 20px; /* Espaciado consistente con otros campos */
    border: 2px solid #ddd; /* Borde más definido */
    border-radius: 8px; /* Bordes más redondeados */
    box-sizing: border-box;
    transition: border-color 0.3s; /* Transición suave al cambiar el borde */
    background-color: white; /* Fondo blanco para el select */
    color: #333; /* Color de texto similar al de otros campos */
}

.formulario-contacto select:focus {
    border-color: #547cb8; /* Color del borde al enfocar, similar a inputs */
    outline: none; /* Elimina el outline predeterminado */
}
.social-icon {
    color: black; /* Cambia el color del ícono */
    margin-right: 10px; /* Añade un margen a la derecha */
}

.social-icon:last-child {
    margin-right: 0; /* Elimina el margen para el último ícono */
}
@media (max-width: 768px) {
    .contacto-container {
        padding: 10px; /* Reducir el padding en dispositivos móviles */
    }
    .contacto-container h1{
        font-size: 22px; /* Tamaño reducido para dispositivos móviles */
        margin-left: 0;
    }

    .formulario-contacto {
        width: calc(100% - 20px); /* Ajustar el ancho del formulario para dispositivos móviles */
        margin: 10px; /* Agregar margen en dispositivos móviles */
        padding: 10px; /* Reducir el padding en dispositivos móviles */
    }

    .formulario-contacto input,
    .formulario-contacto textarea,
    .formulario-contacto select,
    .mapa iframe {
        width: calc(100% - 20px); /* Ajustar el ancho de los elementos del formulario y mapa */
        margin: 0; /* Eliminar el margen en dispositivos móviles */
    }
}
