/* AcercaDe.css */ 
.acerca-de-container { 
    text-align: center; /* Centra el texto y el contenido */ 
    padding: 40px; /* Incrementa el padding alrededor del contenido para más espacio */ 
    padding-top: 10px; 
} 
.acerca-de-container h1 { 
    margin-top: 0; 
    margin-bottom: 40px; /* Aumenta el espacio debajo del título */ 
    font-size: 46px; /* Tamaño más grande para el título */ 
    color: #547cb8; /* El título en azul */ 
} 
.acerca-de-container h2 { 
    color: #547cb8; /* Color azul para el subtítulo */ 
    font-size: 24px; /* Tamaño de fuente para el subtítulo */ 
    margin-bottom: 40px; /* Aumenta el espacio debajo del subtítulo */ 
    font-weight: normal; /* Asegura que el texto no esté en negrita */ 
} 
.acerca-de-container p { 
    color: black; /* Asegura que el texto sea negro */ 
    text-align: justify; /* Justifica el texto del párrafo para mejor lectura */ 
    margin-bottom: 40px; /* Aumenta el espacio debajo del párrafo */ 
    max-width: 800px; /* Limita el ancho del texto para una lectura cómoda */ 
    margin-left: auto; /* Centra el bloque de texto horizontalmente */ 
    margin-right: auto; 
} 
.acerca-de-container img { 
    width: 100%; /* Hace que la imagen se estire para cubrir el ancho del contenedor */ 
    height: auto; /* Ajusta la altura automáticamente para mantener las proporciones */ 
    margin-bottom: 40px; /* Aumenta el espacio debajo de la imagen */ 
    max-height: 70vh; /* Mantiene la proporción de la imagen ajustando la altura automáticamente */ 
    align-items: start; 
} 
.video-container {
    position: relative;
    overflow: hidden; /* Mantiene el video dentro del contenedor */
    max-width: 98%; /* Ajusta el ancho máximo del contenedor del video */
    margin: 20px auto; /* Agrega un margen arriba y abajo y centra el contenedor horizontalmente */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade una sombra sutil para el efecto flotante */
    border-radius: 80px; /* Bordes redondeados para el contenedor */
}

.video-container video {
    width: 70%; /* El video se ajustará al ancho del contenedor */
    height: auto; /* Mantiene la relación de aspecto del video */
    object-fit: contain; /* Asegura que el video se muestre completo */
    border-radius: 80px; /* Bordes redondeados aplicados al video */
}

.boxes-container { 
    display: flex; 
    justify-content: space-evenly; 
    padding-bottom: 20px; /* Añade algo de espacio debajo de los recuadros */ 
    align-items: flex-start; 
} 
.overlay { 
    position: absolute; 
    top: 0; /* Posiciona los recuadros en la parte superior del video */ 
    left: 0; 
    right: 0; 
    display: flex; 
    justify-content: space-evenly; 
    text-align: center; 
    padding-top: 20px; /* Añade algo de espacio en la parte superior */ 
    align-items: flex-start; 
} 
.box { 
    background: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente para las cajas */ 
    border-radius: 10px; 
    padding: 20px; 
    width: 30%; /* Ajusta el ancho de las cajas */ 
    margin: 0 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade sombra para mejor visibilidad */ 
} 
.box h3 { 
    margin-bottom: 10px; 
    color: #547cb8; /* Mantiene el color de los títulos */ 
} 
.box p {
    font-size: 18px; /* Tamaño de fuente igual al de .servicio p en Servicios.css */
    line-height: 1.3; /* Alinea la altura de línea con .servicio p */
    text-align: left; /* Asegura que el texto esté alineado a la izquierda, igual que en servicios */
    color: black; /* El color ya es negro, pero lo dejamos para confirmar la consistencia */
    margin-bottom: 15px; /* Ajusta el margen inferior para que coincida con .servicio p */
    /* Asegúrate de que no haya otros estilos que sobrescriban estos valores */
}
/* Asegúrate de que los estilos de .acerca-de-container se mantienen como estaban */
/* Media Queries para Responsividad en Móviles */
@media (max-width: 768px) {
    .acerca-de-container {
        padding: 20px;
        padding-top: 10px;
    }

    .acerca-de-container h1 {
        font-size: 22px; /* Tamaño reducido para dispositivos móviles */
        margin-left: 0;
        font-weight: normal;
    }

    .acerca-de-container h2 {
        font-size: 20px; /* Ajusta el subtítulo para que sea más legible en pantallas pequeñas */
        margin-bottom: 20px;
        font-weight: normal;
    }

    .acerca-de-container p {
        font-size: 16px; /* Ajusta el tamaño de fuente para mejorar la legibilidad */
        margin-bottom: 20px;
        padding: 0 5px; /* Reduce el padding para evitar que el texto toque los bordes de la pantalla */
        font-weight: normal;
    }

    .acerca-de-container img, .video-container video {
        width: 100%; /* Asegura que las imágenes y videos ocupen todo el ancho disponible */
        max-height: 50vh; /* Limita la altura para evitar que dominen la pantalla */
    }

    .boxes-container, .overlay {
        display: flex;
        flex-direction: column; /* Cambia la disposición de los elementos a vertical */
        align-items: center; /* Centra los elementos en la pantalla */
    }

    .box {
        width: 90%; /* Ajusta el ancho de las cajas para que se adapten mejor a pantallas pequeñas */
        margin: 10px 0; /* Aumenta el espacio entre cajas para evitar que se sientan apretadas */
    }
}