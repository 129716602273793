@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

header {
  text-align: center;
  background-color: var(--background-color); /* Usa la variable para el color de fondo */
  color: var(--text-color); /* Usa la variable para el color del texto */
  padding: 55px 0;
  margin-left: 20px;
  
}

.logo-container img {
  max-width: 30%;
  height: auto;
  animation: bounce 4s infinite;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  
}

.nav-links li a {
  color: var(--link-color); /* Ahora usa la variable específica para el color de enlaces */
  text-decoration: none;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  padding: 10px 20px;
  transition: color 0.3s, background-color 0.3s;
}

.nav-links li a:hover, .nav-links li a:focus {
  color: var(--accent-color); /* O cualquier otro color que desees para hover/focus */
  background-color: var(--background-color);
}


/* Tus media queries y otros estilos siguen aquí */


.header-container, .nav-links li a {
  font-family: 'Montserrat', sans-serif;
  
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
  header {
    padding: 20px 0; /* Reduce el espaciado superior e inferior */
    margin-left: 0; /* Elimina el margen adicional a la izquierda en dispositivos móviles */
  }

  .logo-container img {
    max-width: 50%; /* Ajusta el tamaño del logo para que sea adecuado en móviles */
    margin-left: 10px; /* Corre los enlaces un poco hacia la derecha */
  }

  .nav-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 5px; /* Mantiene el espacio reducido entre enlaces */
    margin-top: 20px; /* Espacio reducido sobre los enlaces */
    margin-left: 5px; /* Corre los enlaces un poco hacia la derecha */
  }
  
  .nav-links li a {
    color: #547cb8;
    text-decoration: none;
    font-size: 12px; /* Tamaño de fuente ajustado para dispositivos móviles */
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    padding: 5px; /* Espaciado ajustado para dispositivos móviles */
    transition: color 0.3s;
  }
  
}
