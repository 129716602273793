/* Estilos Generales para el contenedor de WhatsApp */
.whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Animación para el ícono de chat */
@keyframes emergeFromIcon {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Estilo para el ícono de chat */
.chat-icon {
  background-color: #25D366;
  border-radius: 50%;
  width: 100px; /* Tamaño original para PC */
  height: 100px; /* Tamaño original para PC */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  animation: pulse 2s infinite;
}

/* Estilos para la imagen dentro del ícono de chat */
.chat-icon img {
  width: 100%;
  height: auto;
}

/* Estilos para la caja de chat y el mensaje de bienvenida */
.chat-box, .welcome-message {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  padding: 20px;
  position: fixed;
  right: 20px;
  width: 300px; /* Ancho fijo para PC */
  bottom: 120px;
  animation: emergeFromIcon 0.5s ease-out forwards;
  opacity: 0;
  transform-origin: bottom right;
}

/* Estilo para la "cola" de la burbuja de chat */
.chat-box::after, .welcome-message::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: 50px;
  border: 10px solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px;
}

/* Estilos para textos dentro de la caja de chat */
.welcome-message, .chat-message, .service-title {
  font-size: 18px;
}

/* Estilos para las opciones de chat */
.chat-option {
  font-size: 16px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.chat-option:hover {
  background-color: #e0e0e0;
}

/* Estilos para el formulario de chat */
.chat-form input, .chat-form button {
  font-size: 16px;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chat-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* Ajustes específicos para dispositivos móviles */
@media (max-width: 768px) {
  .chat-icon {
    width: 70px; /* Tamaño ajustado para dispositivos móviles */
    height: 70px; /* Tamaño ajustado para dispositivos móviles */
  }

  .chat-box, .welcome-message {
    width: calc(50% - 5px); /* Utiliza todo el ancho disponible, con margen */
    bottom: 100px; /* Posición ajustada para dispositivos móviles */
  }
}
