/* inicio.css*/
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .main-title, .sub-title {
    animation: fadeIn 3s ease-in;
  }
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos hijos verticalmente */
    text-align: center; /* Alineación de texto para elementos internos */
    width: 100%; /* Ocupa el ancho total disponible */
    max-width: 1200px; /* Establece un ancho máximo si es necesario para evitar que se extienda demasiado en pantallas grandes */
    margin: auto; /* Centra el contenedor en el viewport */
}

h1 {
    color: #547cb8;
    font-size: 46px; /* Tamaño original para PCs */
    margin-bottom: 20px;
    margin-left: 3%;
}

p {
    color: #547cb8;
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 3%;
}

.img-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 20px 0; /* Asegura un margen superior e inferior */
}

.img-horizontal img,
.img-horizontal video {
    width: 100%; /* Asegura que el video ocupe todo el ancho de su contenedor */
    max-height: 80vh; /* Aumenta la altura máxima para que el video sea más grande */
    object-fit: cover; /* Mantiene la proporción del video mientras cubre el espacio disponible */
    border-radius: 10px; /* Mantiene los bordes redondeados */
}


.left-arrow, .right-arrow {
    position: absolute;
    top: calc(50% - 20px); /* Ajustado para centrar mejor las flechas */
    font-size: 24px;
    color: #547cb8;
    cursor: pointer;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.horizontal-item {
    flex: 0 0 auto; /* Mantiene el tamaño basado en el contenido */
    margin: 0 10px 20px; /* Añade un margen inferior para separar de otros elementos */
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #ddd;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    background-color: #fff;
}

.horizontal-icon {
    width: 80px; /* Ajuste de tamaño de iconos */
    height: 80px; /* Mantiene la proporción */
    margin-bottom: 10px; /* Espacio entre el icono y el texto */
}

.horizontal-info {
    text-align: center; /* Asegura que el texto esté centrado */
    color: #547cb8;
    font-size: 16px; /* Ajuste opcional del tamaño de fuente según necesidad */
}

/* Ajustes adicionales para centrar los .horizontal-item */
.horizontal-display {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Asegura el centrado horizontal de los elementos */
    align-items: center;
    flex-wrap: wrap; /* Permite el envolvimiento para adaptarse a diferentes anchos de pantalla */
    gap: 20px; /* Espacio entre los elementos cuando se envuelven */
    padding: 0; /* Asegúrate de que no haya padding adicional empujando los elementos */
    margin: auto; /* Centra el contenedor si tiene un ancho máximo establecido */
    margin-left: 300px;
}

.footer {
    color: #547cb8;
    font-size: 14px; /* Ajusta este valor según sea necesario */
    width: 100%;
    text-align: center;
    padding: 20px 0;
    display: flex;
    flex-direction: column; /* Organiza el contenido del footer en columna */
    align-items: center; /* Centra el contenido */
    justify-content: center; /* Centrado vertical para todo el contenido del footer */
}

.social-icons {
    display: flex; /* Dispone los íconos en línea */
    justify-content: center; /* Centra los íconos horizontalmente */
    align-items: center; /* Alinea los íconos verticalmente al centro */
    flex-wrap: nowrap; /* Previene el envolvimiento de los íconos */
    margin-bottom: 10px; /* Espacio entre los íconos y el texto de copyright */
}

.social-icons a {
    margin: 0 10px; /* Espacio entre íconos */
}

.social-icons img {
    width: 100px; /* Tamaño de los íconos */
    height: 100px; /* Mantiene la proporción de los íconos */
}


/* Media Queries para Responsividad en Móviles */
@media (max-width: 768px) {
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      
      .main-title, .sub-title {
        animation: fadeIn 2s ease-in;
      }
      
      .horizontal-display {
        justify-content: center; /* Asegura el centrado horizontal */
        flex-direction: column; /* Cambia la disposición a vertical */
        align-items: center; /* Centra los elementos verticalmente */
        margin: 20px 0; /* Añade un poco de margen superior e inferior */
        gap: 5px; /* Reduce el espacio entre los elementos cuando se envuelven */
    }

    .horizontal-item {
        width: 80%; /* Ajusta el ancho de los recuadros para que se adapten mejor a pantallas pequeñas */
        margin: 5px 0; /* Reduce el espacio alrededor de cada elemento para que estén más pegados */
        padding: 8px; /* Ajusta el padding interior si es necesario para reducir el tamaño del recuadro */
    }

    .horizontal-icon {
        width: 40px; /* Mantiene el ajuste del tamaño de los íconos para dispositivos móviles */
        height: 40px; /* Mantiene la proporción */
        margin-bottom: 5px; /* Reduce el espacio entre el ícono y el texto */
    }

    .horizontal-info {
        font-size: 12px; /* Mantiene el ajuste del tamaño de fuente para adaptarse a pantallas más pequeñas */
    }

    .img-horizontal video {
        width: 100%; /* Asegura que el video se ajuste al ancho de la pantalla */
        max-height: 50vh; /* Limita la altura del video para no ocupar demasiado espacio vertical */
    }

    .left-arrow, .right-arrow {
        font-size: 20px; /* Mantiene el ajuste del tamaño de las flechas */
    }

    h1, p {
        font-size: 18px; /* Mantiene el ajuste del tamaño del texto */
        margin: 10px 0; /* Ajusta los márgenes para reducir el espacio vertical */
    }

    .footer {
        padding: 10px 0; /* Reduce el padding en dispositivos móviles */
    }

    .social-icons a {
        margin: 0 8px; /* Ajusta el margen entre íconos para dispositivos más pequeños */
    }

    .social-icons img {
        width: 40px; /* Ajusta el tamaño de los íconos para móviles */
        height: 40px; /* Mantiene la proporción */
    }
}
